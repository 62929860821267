import React, { useEffect, useState } from "react";
import "./Property.scss";
import { useParams, Link } from "react-router-dom";
import NavHeader from "../templates/Header/Header";
import Footer from '../templates/Footer/Footer';
import PropertyService from "../../services/Properties";
import { Label, Loader } from "semantic-ui-react";
import { Container, Row, Col } from "react-bootstrap";
import { APP_URL } from "../../utils/config";
import Fancybox from "../../utils/Fancybox";
import { Header, List, Image, Icon, Divider, Button } from "semantic-ui-react";
import Helper from "../../utils/helper";
import LocationMap from "../../utils/map";
import Slider from "react-slick";
import "../../App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderRef = React.createRef();

  // slider configuration
  const options = {
    prevArrow: <div className="custom-arrow"></div>,
    nextArrow: <div className="custom-arrow"></div>,
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings = {
    autoplay: false,
    dots: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

const Property = () => {
  const { property_id } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);


  const helper = new Helper();
  useEffect(() => {
    const propertyInstance = new PropertyService();
    const fetchData = async () => {
      try {
        // Set loading to true when starting the request
        setLoading(true);

        const data = await propertyInstance.getProperty(property_id);
        setPropertyData(data);

        // Set loading to false when the request is complete
        setLoading(false);
      } catch (error) {
        console.error("Error fetching property:", error.message);

        // Set loading to false in case of an error
        setLoading(false);
      }
    };

    const fetchProperties = async () => {
      try {
        setLoading(true);
        const data = await propertyInstance.list();
        setProperties(data);
        console.log(data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching properties:", error);
      }
    };

     
    fetchProperties();

    fetchData();
  }, [property_id]);



  return (
    <div className="Property" data-testid="Property">
      <NavHeader />

      <Container style={{ marginTop: "40px" }}>
        {loading && <Loader active inline="centered" />}

        {!loading && propertyData && (
          <Row className="d-flex justify-content-center">
            <Col md="12">
              <Row>
                <Col md="6">
                  <Header as="h1">
                    {helper.capitalize(propertyData.property_title)}
                  </Header>

                  <div>
                    <List divided horizontal>
                      <List.Item>
                        {propertyData.property_category === "Hotel" ? (
                          <Icon name="hotel" />
                        ) : (
                          <Icon name="home" />
                        )}
                        <span> {propertyData.property_category}</span>
                      </List.Item>
                      <List.Item>
                        <Icon name="map marker alternate" />{" "}
                        <span>
                          {" "}
                          {propertyData.address}, {propertyData.province}
                        </span>{" "}
                      </List.Item>
                    </List>
                  </div>
                  <Header as="h3" className="text-red">
                      {helper.toCurrency(parseFloat(propertyData.price, 2))}
                    </Header>
                  <br />
                </Col>
              </Row>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                    transition: "slide",
                    Panzoom: {
                      decelFriction: 0.5,
                    },
                  },

                  Toolbar: {
                    display: {
                      left: ["infobar"],
                      middle: [
                        "zoomIn",
                        "zoomOut",
                        "toggle1to1",
                        "rotateCCW",
                        "rotateCW",
                        "flipX",
                        "flipY",
                      ],
                      right: ["slideshow", "thumbs", "close"],
                    },
                  },
                }}
              >
                <div className="grid-container">
                  {propertyData.images.length > 0 && (
                    <div className="grid">
                      {propertyData.images.length >= 5 ? (
                        <>
                          <a
                            href={`${APP_URL}/${propertyData.images[0].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item large-item f-panzoom"
                            id="myPanzoom"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[0].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[1].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[1].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[2].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[2].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[3].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[3].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <div className="grid-item small-item">
                            <div className="overlay-item">
                              <a
                                className="overlay"
                                href={`${APP_URL}/${propertyData.images[4].image_path}`}
                                data-fancybox="gallery"
                              >
                                <div>
                                  + {propertyData.images.slice(4).length}
                                </div>
                              </a>
                              {propertyData.images
                                .slice(4)
                                .map((image, index) => (
                                  <a
                                    key={index}
                                    href={`${APP_URL}/${image.image_path}`}
                                    data-fancybox="gallery"
                                  >
                                    <img
                                      src={`${APP_URL}/${image.image_path}`}
                                      alt={`Property `}
                                    />
                                  </a>
                                ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <a
                            href={`${APP_URL}/${propertyData.images[0].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item f-panzoom"
                            id="myPanzoom"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[0].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[1].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[1].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[2].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                            <img
                              src={`${APP_URL}/${propertyData.images[2].image_path}`}
                              alt={`Property `}
                            />
                          </a>
                          <a
                            href={`${APP_URL}/${propertyData.images[3].image_path}`}
                            data-fancybox="gallery"
                            className="grid-item small-item"
                          >
                          
                          </a>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Fancybox>
              <br />
              <Row>
                <Col md="7">
                <p>
                    <Label>{propertyData.property_request}</Label>
                  </p>
                  <p>{propertyData.property_description}</p>
                  <div className="map">
                    <LocationMap propertyData={propertyData} />
                  </div>
                </Col>
                <Col md="5">
                  <div className="box">
                  <Header as="h4">Contact Information</Header>
                    <Divider />
                    <Header as="h4"><Image src={propertyData.user[0].profile_path === '' ? '/assets/images/avatar.png' : APP_URL+'/'+propertyData.user[0].profile_path} alt="avatar" avatar /> <span>{propertyData.user[0].firstName} {propertyData.user[0].lastName}</span></Header>
                    <p><a href={`mailto:${propertyData.user[0].email}`}>{propertyData.user[0].email}</a></p>
                    <p>{propertyData.phone_number}</p>
                    <Divider />
                    <Link>
                      <Button negative className="w-100">
                        Contact
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
      
      <br />
      <Container>
      <Header as="h2">Similar Properties</Header>
        <Divider />
        {loading && <Loader active inline="centered" />}

        {!loading && properties && (
        <Slider className="properties" ref={sliderRef} {...settings}>
        {properties
          .filter((property) => property.property_images.length > 0)
          .map((property) => (
            <div className="property-col" key={property.id}>
              <div className="property-card">
                <div className="label">{property.property_request}</div>

                <div className="property-image-carousel">
                  <Slider className="property-image" {...options}>
                    {property.property_images.map((image, index) => (
                      <div className="property-slider" key={index}>
                        <a  href={`/property/${property.id}`}>
                        <img
                          alt={`Property ${index + 1}`}
                          src={`https://api.imbapano.com/${image.image_path}`}
                        />
                        </a>
                      </div>
                    ))}
                  </Slider>
                  <div class="property-agent">
                    <a href="#link">
                      <img
                        src={
                          property.avatar
                            ? `https://api.imbapano.com/${property.avatar}`
                            : "/assets/images/avatar.png"
                        }
                        alt="User Avatar"
                      />
                    </a>
                  </div>
                  <div class="details">
                    <div class="property-name">
                      <a href="details">{property.property_title} </a>
                    </div>
                    <div class="property-price">
                      <p>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(property.price)}
                      </p>
                    </div>
                  </div>

                  <div class="address">
                    <p>
                      <i class="fa fa-map-marker"></i> {property.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
        )}
      </Container>

      <Footer />
    </div>
  );
};

export default Property;
