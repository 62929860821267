import React from 'react';
import './Footer.scss';
import { Container, Grid, Header, List, Divider, Image } from 'semantic-ui-react';

const Footer = () => {
    return(
    <div className="Footer">
        <div className="footer-hero">
            <h4>We help people and homes find each other </h4>
        </div>

        <footer className="footer">
        <Container>
          <Grid columns={5} stackable>
            <Grid.Column>
              <a href="#">
                <Image src="/assets/images/logo.png" alt="Logo" size="medium" className="footer-logo" />
              </a>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <Header as="h4">Features</Header>
                </List.Item>
                <List.Item><a href="#">Imbano App</a></List.Item>
                <List.Item><a href="#">Affordability Calculator</a></List.Item>
                <List.Item><a href="#">Estimate Value</a></List.Item>
                <List.Item><a href="#">Premium Ads</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List style={{ listStyle: 'none' }}>
                <List.Item>
                  <Header as="h4">Company</Header>
                </List.Item>
                <List.Item><a href="#">About Us</a></List.Item>
                <List.Item><a href="#">Testimonials</a></List.Item>
                <List.Item><a href="#">Careers</a></List.Item>
                <List.Item><a href="#">Blog</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <Header as="h4">Support</Header>
                </List.Item>
                <List.Item><a href="#">FAQ</a></List.Item>
                <List.Item><a href="#">Rental Guides</a></List.Item>
                <List.Item><a href="#">Sellers Guides</a></List.Item>
                <List.Item><a href="#">Contact Us</a></List.Item>
                <List.Item><a href="#">Feedback</a></List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Container>

        <Divider />

        <Container>
          <Grid columns={2} stackable>
            <Grid.Column width={16} textAlign="center">
              <p>&copy; 2023 Imba Pano</p>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
              <List horizontal divided link size="small">
                <List.Item>
                  <a href="#" className="footer-img">
                    <Image src="/assets/images/icon/playstore.png" />
                  </a>
                </List.Item>
                <List.Item>
                  <a href="#" className="footer-img">
                    <Image src="/assets/images/icon/applestore.png" />
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Container>
      </footer>
    </div>
    )
}

export default Footer;