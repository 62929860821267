import { useEffect, useState } from "react";
import NavHeader from "../templates/Header/Header";
import SideBar from "../templates/SideBar/SideBar";
import { Link } from "react-router-dom";
import {
  Grid,
  Container,
  Icon,
  Segment,
  Button,
  Header,
  Label,
  Divider,
  Image,
  Loader
} from "semantic-ui-react";
import "./Account.scss";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Account = () => {
  let [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('https://api.imbapano.com/api/user', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const userData = await response.json();
            localStorage.setItem('user', JSON.stringify(userData));
            setUser(userData);
            
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false); // Set loading to false whether successful or not
        }
      }
      else{

      }
    };

    fetchData();
  }, []);


  return (
    <div className="Account" data-testid="Account">
      <NavHeader />

      <Container style={{ marginTop: "20px" }}>
      <Grid stackable>
          <Grid.Row>
            <Grid.Column width={4} className="sideBar">
              <SideBar />
            </Grid.Column>
            <div>
                <Icon name="bars" />
              </div>

            <Grid.Column width={12}>
            {loading ? (
              <Loader active inline="centered" />
            ) : (
              <>
                {/* Profile Overview */}
                <Segment className="remove-style">
                  <Header as="h4">
                    {user.profile_path ? (
                      <Image avatar src={user.profile_path} />
                    ) : (
                      <Image avatar src="/assets/images/default-avatar.png" />
                    )}
                    <Header.Content>
                      {user.firstName} {user.lastName}
                      <Header.Subheader>{user.email}</Header.Subheader>
                      <Header.Subheader>{user.phone}</Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Label color="teal" size="tiny">
                    {user.userType}
                  </Label>

                  <Divider />

                  {/* Buttons */}
                  {/* <Link to="/post-property">
                  <Button
                    negative
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="add" />
                    Post New Property
                  </Button>
                  </Link> */}
                  
                  <Link to="/edit-profile">
                  <Button
                    color="green"
                    size="mini"
                    icon
                    labelPosition="left"
                  >
                    <Icon name="edit" />
                    Edit Profile
                  </Button>
                  </Link>
                </Segment>

                <Segment className="remove-style">
                <Header as="h4">
                  <Icon name="list alternate outline" />
                  <Header.Content>Property Listings</Header.Content>
                </Header>
                {/* Sample Property Listings */}

                <p>You have not listed any property yet</p>
              </Segment>

              {/* Favorites and Saved Properties */}
              <Segment className="remove-style">
                <Header as="h4">
                  <Icon name="heart" />
                  <Header.Content>Favorites</Header.Content>
                </Header>

                <p>Nothing here</p>
              </Segment>

              <Segment className="remove-style">
                <Header as="h4">
                  <Icon name="bell outline" />
                  <Header.Content>Notifications</Header.Content>
                </Header>

                <p>No new notification</p>
              </Segment>
              </>
            )}
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default Account;
