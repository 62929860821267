import React, { useState, useEffect } from 'react';
import { Input, Menu, MenuItem,  Dropdown, Icon, Image, Button, Loader, Header, Sidebar, SidebarPusher,
    SidebarPushable, MenuHeader, MenuMenu, GridColumn, Checkbox, Segment} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { API_URL, APP_URL } from '../../utils/config';
import Property from '../../services/Properties';
import Helper from '../../utils/helper';
import $ from 'jquery';

const Mobile = () =>{
    const [visible, setVisible] = React.useState("none");
    const [isLoggedIn, setLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [loading, setLoading] = useState(false)
    const [none, setNone] = useState('none');
    const [results, setResults] = useState('');
    const [message, setMessage] = useState('');
    const [userQuery, setUserQuery] = useState('');

    const handleInput = (e) =>{
      setLoading(true);
      setNone('block');
      const query = e.target.value;
      if(query.length === 0){
        setLoading(false);
        setNone('none');
        return;
      }
      setUserQuery(query);
  
      try{
        fetch(`${API_URL}/search/property?query=${query}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if(!response.ok){
            throw new Error('An error occured while fetching data');
          }
  
          return response.json();
        })
        .then((data) => {
          if (data.status_code === 200) {
            setResults(data.results);
            setMessage('');
          } else {
            setResults([]);
            setMessage('No results found.');
          }
        }).catch(error => {
          setMessage('Something went wrong');
        })
        .finally(() => {
          setLoading(false);
        });
      }
      
      catch(error){
        console.log(error);
        setLoading(false);
      }
    }
  
    const handleInputFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleInputBlur = () => {
      setIsInputFocused(false);
    };
  
    const handleLinkClick = () =>{
      setNone('none');
      setMessage('');
      setResults([]);
    }
  
  
    useEffect(() => {
      const token = localStorage.getItem('token');
      const helper = new Helper();
      if(token === 'undefined'){
        setLoggedIn(false);
        helper.clearStorage();
      }
      
      if(token  && !localStorage.getItem('user')){
        if(!user){
          try {
            $.ajax({
              type: "GET",
              url: 'https://api.imbapano.com/api/user',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
              success: function(response){
                console.log(response);
                localStorage.setItem('user', JSON.stringify(response));
              }
            })
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        }
      }
  
      if(localStorage.getItem('user')){
        setUser(JSON.parse(localStorage.getItem('user')));
      }
      if(token){
        setLoggedIn(true);
      }
  
    },[]);
  
    const [properties, setProperties] = useState([]);
    useEffect(() => {
      const fetchProperties = async () => {
        try {
          const propertyObj = new Property();
          const data = await propertyObj.list();
          setProperties(data);
        } catch (error) {
          console.error("Error fetching properties:", error);
        }
      };
  
      fetchProperties();
    }, []);
    
   
    const handleLogout = () => {
      setLoggedIn(false);
      // Clear localStorage items
      localStorage.clear();
      window.location.href="/";
    };

    // Toggle Menu
    const toggleMenu = () => {
      if(visible === "none"){
        setVisible("block");
      }
        else{
        setVisible("none");
      }
    }
   
    return (
      <div>
        <div className="MobileNav">
            <div className="topHeader">
                <div className="mobileLogo">
                <img alt="logo" className="logo" src='/assets/images/logo.png' />
              </div>
              {isLoggedIn && user && user.is_verified !== undefined ? (
                <>
                  <div>
                   <Link to="/post-property">
                    <Button negative size="mini" icon labelPosition="left">
                            <Icon name="add" />
                        Post Property
                      </Button>
                    </Link>
                </div>

                <div>
                <Dropdown
              trigger={
                <>
                {user && user.profile_path ? (
                  <Image avatar src={user.profile_path} />
                ) : (
                  <Image avatar src='/assets/images/default-avatar.png' />
                )}
              </>
              }
              floating
              direction="left"
              className="item userItem"
            >
              <Dropdown.Menu>
                <Dropdown.Header></Dropdown.Header>
                
                {user.is_verified === 1 ? (
        <>
          <Dropdown.Item>
            <Link to="/profile">Profile</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/account">Manage Account</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/bookmarks">Bookmarks</Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="/payment">Payment</Link>
          </Dropdown.Item>
        </>
      ) : (
        <Dropdown.Item>
          <p style={{ color: 'red' }}>
            Your phone number is not yet verified. 
          </p>
          <Link to="/verify">
            <Button size="mini">
              Verify Now
            </Button>
          </Link>
        </Dropdown.Item>
      )}
                <Dropdown.Item onClick={handleLogout}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
                </div>
                </>
              ): (
                  <>
                    <div>
                    <Link to="/login">
                      <Button negative size="tiny">Login</Button>
                    </Link>
                    </div>
                    <div>
                    <Link to="/signup">
                      <Button primary size="tiny">Sign Up</Button>
                    </Link>
                    </div>
                  </>  
                )}
              <div>
                <Icon name="bars" onClick={toggleMenu} />
              </div>
            </div>

            <div className="menu" style={{display: visible}}>  
              <Menu stackable size="small" className="borderless navMenu">
              <Menu.Item>
    <Input 
      loading={loading}
      icon='search'
      placeholder='Search...' 
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      onKeyUp={handleInput}
    />

    <div className="search-result" style={{display: none}}>
      <Header as="h4" style={{padding: '10px'}}>Search results</Header><hr/>

      {loading &&  <Loader inline active size="mini" />}

        {results.length > 0 && (
          <div className="search-result-list">
            {results.map((result) => (
              <Link key={result.id} to={`/search?q=${userQuery}`} onClick={handleLinkClick}>
                <div className="search-result-item">
                  {/* <div className="search-result-item-image">
                    <img src={result.property_images.length > 0 ? APP_URL+'/'+result.property_images[0].image_path : 'default_image_url'} alt="building" />
                  </div> */}
                  <div className="search-result-item-content">
                    <small>Houses in</small>
                    <div className="search-result-item-title">
                      <Header as="h4">{result.property_title}</Header>
                    </div>
                    <div className="search-result-item-address">
                      <p>
                        <Icon name="map marker" /> <span>{result.address}</span>
                      </p>
                    </div>
                    {/* <div className="search-result-item-price">
                      $
                      <span>{result.price}</span>
                    </div> */}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {!loading && results.length === 0 && <p>{message}</p>}
    </div>
    </Menu.Item>
              <Menu.Item className='link item'>
                <Link to="/">
                  <Icon name="home" />
                Home
                </Link>
              </Menu.Item>
              <Menu.Menu>
                <Dropdown item text='Buy' pointing className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/for-sale">Homes for sale</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/new-estates">New Estates</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/landed-properties">Landed Properties</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu> 

              <Menu.Menu>
              <Dropdown item text='Sale' pointing className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/for-sale">Homes for sale</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/new-estates">New Estates</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/landed-properties">Landed Properties</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu> 

            
              </Menu>
            </div>
            
        </div>
      </div>
    )
  }

  export default Mobile;
  
