import React, {useState, useEffect} from 'react';
import './Search.scss';
import { useLocation, Link } from "react-router-dom";
import PropertyService from "../../services/Properties";
import NavHeader from "../templates/Header/Header";
import Footer from '../templates/Footer/Footer';
import { Container, Row, Col } from "react-bootstrap";
import { Label, Loader } from "semantic-ui-react";
import Slider from "react-slick";

const options = {
  prevArrow: <div className="custom-arrow"></div>,
  nextArrow: <div className="custom-arrow"></div>,
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Search = () => {


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q');

  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const propertyInstance = new PropertyService();

    const fetchProperties = async () => {
      try {
        setLoading(true);
        const data = await propertyInstance.list();
        setProperties(data);
        setLoading(false);
        console.log('property', data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching properties:", error);
      }
    };

     
    fetchProperties();
  }, [query]);

  return (
  <div className="Search" data-testid="Search">
    <NavHeader />

    <Container style={{ marginTop: "40px" }}>
        {loading && <Loader active inline="centered" />}

        {!loading && properties && (
          
          <Row className="d-flex justify-content-center search-properties">
            {properties
          .filter((property) => property.property_images.length > 0)
          .map((property) => (
            <Col md={4} className="property-col" key={property.id}>
              <div className="property-card">
                <div className="label">{property.property_request}</div>

                <div className="property-image-carousel">
                  <Slider className="property-image" {...options}>
                    {property.property_images.map((image, index) => (
                      <div className="property-slider" key={index}>
                        <a  href={`/property/${property.id}`}>
                        <img
                          alt={`Property ${index + 1}`}
                          src={`https://api.imbapano.com/${image.image_path}`}
                        />
                        </a>
                      </div>
                    ))}
                  </Slider>
                  <div class="property-agent">
                    <a href="#link">
                      <img
                        src={
                          property.avatar
                            ? `https://api.imbapano.com/${property.avatar}`
                            : "/assets/images/avatar.png"
                        }
                        alt="User Avatar"
                      />
                    </a>
                  </div>
                  <div class="details">
                    <div class="property-name">
                      <a href="details">{property.property_title} </a>
                    </div>
                    <div class="property-price">
                      <p>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(property.price)}
                      </p>
                    </div>
                  </div>

                  <div class="address">
                    <p>
                      <i class="fa fa-map-marker"></i> {property.address}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
          </Row>
        )}
      </Container>

    <Footer />
  </div>
  )};


export default Search;
