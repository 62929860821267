import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import 'semantic-ui-css/semantic.min.css';
import './Header.scss';


import Desktop from '../Desktop';
import Mobile from '../Mobile';


const TemplatesHeader = () => {

  const breakpoint = 768;

 
  const screenWidth = window.innerWidth;

  const renderComponent = () => {
    if (screenWidth < breakpoint) {
      return <Mobile />;
    } else {
      return <Desktop />;
    }
  };

  return (
    <div>
      {renderComponent()}
    </div>
)};


export default TemplatesHeader;
