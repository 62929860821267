import React, { useEffect, useState } from 'react';
import NavHeader from '../templates/Header/Header';
import { Link } from "react-router-dom";
import SideBar from "../templates/SideBar/SideBar";
import {
    Grid,
    Container,
    Segment,
    Button,
    Header,
    Form,
    FormGroup,
    Divider,
    Image,
    Loader
  } from "semantic-ui-react";
  import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_URL } from '../../utils/config';

const EditProfile = () => {
    const [user, setUser] = useState({
        profileImage: null,
      });
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const response = await fetch('https://api.imbapano.com/api/user', {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
  
            if (response.ok) {
              const userData = await response.json();
              localStorage.setItem('user', JSON.stringify(userData));
              setUser(userData);
              
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            setLoading(false); // Set loading to false whether successful or not
          }
        }
        else{
  
        }
      };
  
      fetchData();
    }, []);

    const changeProfilePicture = () => {
        document.getElementById('profileImageInput').click();
      }

      const handleFileChange = (e) => {
        alert('yes');
        const file = e.target.files[0];
    
        // Update the state with the selected profile image
        setUser({ ...user, profileImage: file });
    
        if (file) {
          const formData = new FormData();
          formData.append('profileImage', file);
    
          fetch(`${API_URL}/user/change-profile-picture`, {
            method: 'POST',
            body: formData,
          })
            .then(response => response.json())
            .then(data => {
              console.log('Profile image uploaded:', data);
            })
            .catch(error => {
              console.error('Error uploading profile image:', error);
            });
        }
      };
    
    return (
        <>
            <NavHeader />

            <Container style={{ marginTop: "20px" }}>
      <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <SideBar />
            </Grid.Column>

            <Grid.Column width={8}>
            {loading ? (
              <Loader active inline="centered" />
            ) : (
              <>
                {/* Profile Overview */}
                <Segment className="remove-style">
                  <Header as="h4">
                    {user.profile_path ? (
                      <Image avatar src={user.profile_path} />
                    ) : (
                      <Image avatar src="/assets/images/default-avatar.png" />
                    )}
                    <Header.Content>
                        <Button size='mini' onClick={changeProfilePicture}>Change profile picture</Button>
                        <Form>
                        <Form.Field>
                            {/* Hidden file input */}
                            <input
                            type='file'
                            accept='image/*'
                            id='profileImageInput'
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            />
                        </Form.Field>
                        </Form>
                    </Header.Content>
                  </Header>
                  <Divider />

                </Segment>

                <Segment className="remove-style">
                <Form>
                        <FormGroup widths='equal'>
                        <Form.Input
                            label='First name'
                            placeholder='First name'
                            value={user.firstName}
                            onChange={(e) => setUser({ ...user, firstName: e.target.value})}
                        />
                        <Form.Input
                            label='Last name'
                            placeholder='Last name'
                            value={user.lastName}
                            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                        />
                        </FormGroup>

                    <Form.Field>
                        <Form.Input
                        label="Email address"
                        placeholder="Email"
                        value={user.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value})}
                        />
                    </Form.Field>

                    <Form.Field>
                    <Button
                        type="submit"
                        color="red"
                        style={{ width: "100%" }}
                      >
                        Save Changes
                      </Button>
                    </Form.Field>
                </Form>
                
              </Segment>

              </>
            )}
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

        </>
    )
}

export default EditProfile;