import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Message, Icon, Button, Form, Divider } from 'semantic-ui-react'
import { Container, Row, Col } from 'react-bootstrap';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../templates/Header/Header';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

 

  return (
  <div className="Login" data-testid="Login">
    <Header />
    <div className="login-container">
      <div className="login-background">
          <div className="login-content">
          <Container>
              <Row className="justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                <Col md="5">
                  <div className="box">
                  <Form className="login-form" loading={loading}>
                  <h4 className="header text-center">Login into your account</h4>
                  <Divider />
                  <Form.Field>
                    <label>Email</label>
                    <Form.Input
                      icon={<Icon name="envelope" color="red" />}
                      iconPosition="left"
                      placeholder="Enter your registered email address"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Field>
                  <Button type="submit" color="red" style={{ width: '100%' }}>
                    Reset Password
                  </Button>
                </Form>

                  {error && (
                      <Message negative style={{width: '100%'}}>
                        <Message.Header></Message.Header>
                        <p>{error}</p>
                      </Message>
                    )}
                    </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
    </div>
  </div>
  )
};

export default ForgotPassword;
