
import { useEffect, useState } from 'react';

import './App.scss';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Index from './components/Index/Index';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Verify from './components/Verify/Verify';
import Account from './components/Account/Account';
import PostProperty from './components/PostProperty/PostProperty';
import DownloadApp from './components/DownloadApp/DownloadApp';
import UploadPropertyImages from './components/UploadPropertyImages/UploadPropertyImages';
import Preview from './components/Preview/Preview';
import Property from './components/Property/Property';
import EditProfile from './components/pages/EditProfile';
import ForgotPassword from './components/pages/ForgotPassword';
import Properties from './components/pages/Properties';
import Search from './components/Search/Search';
import { Loader } from 'semantic-ui-react'
import { API_URL } from './utils/config';


function App() {
  const PrivateRoutes = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(undefined);
        useEffect(() => {
            const fetchData = async () => {
            if (localStorage.getItem('token')) {
                try {
                  fetch(`${API_URL}/user/check`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                  }).then((response) => {
                    if(response.status === 200){
                      setIsAuthenticated(true);
                    }
                    else{
                      setIsAuthenticated(false);
                    }
                  }).catch(error => {
                    throw new Error('Something went wrong', error);
                  });
               
                } catch (error) {
                console.error('Error checking user:', error);
                }
            } else {
                setIsAuthenticated(false);
            }
            };
        
            fetchData();

        }, []);
    
        if (isAuthenticated === undefined) {
        
        return (
            <> 
                <Loader active inline='centered' />
            </>
        ) 
        }
        
    return (
        isAuthenticated ? <Outlet/> : <Navigate to="/login" replace={true}/>
    )
}


  return (
    <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <ProtectedRoute path="/account" element={<Account />} /> */}
          
          <Route path="/search" element={<Search />} />
          <Route path="/property/:property_id" element={<Property />} />
          <Route path="/properties" element={<Properties />} />

          {/* for authenticated users */}
          <Route element={<PrivateRoutes />} >
            <Route path="/account" element={<Account />} />
            <Route path="/post-property" element={<PostProperty />} />
            <Route path="/download-app" element={<DownloadApp />} />
            <Route path="/upload-photos" element={<UploadPropertyImages />} />
            <Route path="/preview" element={<Preview />} />
            <Route path="/edit-profile" element={<EditProfile />} />
        </Route>
        </Routes>
    </Router>
  );
}

export default App;
