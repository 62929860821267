import React from 'react';
import './SideBar.scss';
import { Menu, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const TemplatesSideBar = () => {
  return (
    <Menu vertical className="remove-style">
    {/* Profile Section */}
    <Menu.Item>
      <Menu.Header>Profile</Menu.Header>
      <Menu.Menu>
        <Link to="/account">
          <Menu.Item>
            <Icon name="user" />
            My Account
          </Menu.Item>
        </Link>
        <Link to="/settings">
          <Menu.Item>
            <Icon name="settings" />
            Settings
          </Menu.Item>
        </Link>
      </Menu.Menu>
    </Menu.Item>

    {/* Listings Section */}
    <Menu.Item>
      <Menu.Header>Listings</Menu.Header>
      <Menu.Menu>
        <Link to="/my-listings">
          <Menu.Item>
            <Icon name="list alternate outline" />
            My Listings
          </Menu.Item>
        </Link>
        <Link to="/search-properties">
          <Menu.Item>
            <Icon name="search" />
            Search Properties
          </Menu.Item>
        </Link>
      </Menu.Menu>
    </Menu.Item>

    {/* Favorites Section */}
    <Link to="/favorites">
      <Menu.Item>
        <Icon name="heart" />
        Favorites
      </Menu.Item>
    </Link>

    {/* Financials Section */}
    <Link to="/financials">
      <Menu.Item>
        <Icon name="dollar sign" />
        Financials
      </Menu.Item>
    </Link>

    {/* Settings Section */}
    <Menu.Item>
      <Menu.Header>Settings</Menu.Header>
      <Menu.Menu>
        <Link to="/general-settings">
          <Menu.Item>
            <Icon name="cogs" />
            General
          </Menu.Item>
        </Link>
        <Link to="/help-support">
          <Menu.Item>
            <Icon name="help circle" />
            Help/Support
          </Menu.Item>
        </Link>
      </Menu.Menu>
    </Menu.Item>
  </Menu>
)};


export default TemplatesSideBar;
